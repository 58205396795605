// on hover transition variables
$move-transition:               .2s ease-out !default;
$move-overflow:                 hidden !default;
$move-transform:                perspective(999px) rotateX(0deg) translate3d(0,0,0) !default;
$move-transform-origin:         50% 0 !default;
$move-backface-visibility:      hidden !default;
$move-will-change:              transform,box-shadow !default;
$move-hover-transform:          perspective(999px) rotateX(7deg) translate3d(0px,-4px,5px) !default;

// Sections Height Utilities
$section-height-25-min-height:  25vh !default;
$section-height-35-min-height:  35vh !default;
$section-height-50-min-height:  50vh !default;
$section-height-75-min-height:  75vh !default;
$section-height-85-min-height:  85vh !default;
$section-height-100-min-height: 100vh !default;

// extra padding utilities variables - CT
$padding-6:                     4rem !default;
$padding-7:                     6rem !default;
$padding-8:                     8rem !default;
$padding-9:                     10rem !default;
$padding-10:                    12rem !default;
$padding-11:                    14rem !default;
$padding-12:                    16rem !default;

// extra margins utilities variables - CT
$margin-6:                      4rem !default;
$margin-7:                      6rem !default;
$margin-8:                      8rem !default;
$margin-9:                      10rem !default;
$margin-10:                     12rem !default;
$margin-11:                     14rem !default;
$margin-12:                     16rem !default;

// extra margins utilities variables - CT
$opacity-0:                     0 !default;
$opacity-1:                     .1 !default;
$opacity-2:                     .2 !default;
$opacity-3:                     .3 !default;
$opacity-4:                     .4 !default;
$opacity-5:                     .5 !default;
$opacity-6:                     .6 !default;
$opacity-7:                     .7 !default;
$opacity-8:                     .8 !default;
$opacity-9:                     .9 !default;
$opacity-10:                    1 !default;

// Z-index
$z-index: (
  "0": 0,
  "1": 1,
  "2": 2,
  "3": 3
) !default;

// extra right values variables - CT
$right-0:                     0% !default;
$right-1:                     1% !default;
$right-2:                     2% !default;
$right-3:                     3% !default;
$right-4:                     4% !default;
$right-5:                     5% !default;
$right-6:                     6% !default;
$right-7:                     7% !default;
$right-8:                     8% !default;
$right-9:                     9% !default;
$right-10:                    10% !default;

// extra width values variables - CT
$width-0:                     0% !default;
$width-1:                     1% !default;
$width-2:                     2% !default;
$width-3:                     3% !default;
$width-4:                     4% !default;
$width-5:                     5% !default;
$width-6:                     6% !default;
$width-7:                     7% !default;
$width-8:                     8% !default;
$width-9:                     9% !default;
$width-10:                    10% !default;
$width-15:                    15% !default;
$width-20:                    20% !default;
$width-25:                    25% !default;
$width-30:                    30% !default;
$width-35:                    35% !default;
$width-40:                    40% !default;
$width-45:                    45% !default;
$width-50:                    50% !default;
$width-55:                    55% !default;
$width-60:                    60% !default;
$width-65:                    65% !default;
$width-70:                    70% !default;
$width-75:                    75% !default;
$width-80:                    80% !default;
$width-85:                    85% !default;
$width-90:                    90% !default;
$width-95:                    95% !default;
$width-100:                   100% !default;

// extra max width values variables - CT
$max-width-100:                     100px !default;
$max-width-200:                     200px !default;
$max-width-300:                     300px !default;
$max-width-400:                     400px !default;
$max-width-500:                     500px !default;
$max-width-100-p:                   100% !default;

// extra height values variables - CT
$height-100:                        100px !default;
$height-200:                        200px !default;
$height-300:                        300px !default;
$height-400:                        400px !default;
$height-500:                        500px !default;

// extra min height values variables - CT
$min-height-100:                    100px !default;
$min-height-150:                    150px !default;
$min-height-160:                    160px !default;
$min-height-200:                    200px !default;
$min-height-250:                    200px !default;
$min-height-300:                    300px !default;
$min-height-400:                    400px !default;
$min-height-500:                    500px !default;
$min-height-600:                    600px !default;

// extra min height values variables - CT
$max-height-100:                    100px !default;
$max-height-150:                    150px !default;
$max-height-160:                    160px !default;
$max-height-200:                    200px !default;
$max-height-250:                    200px !default;
$max-height-300:                    300px !default;
$max-height-400:                    400px !default;
$max-height-500:                    500px !default;
$max-height-600:                    600px !default;

// letter spacing - CT
$letter-spacing-1:                  1px !default;
$letter-spacing-2:                  2px !default;
$letter-spacing-3:                  3px !default;
$letter-spacing-4:                  4px !default;
$letter-spacing-5:                  5px !default;

// top values - CT
$top-1:                             1rem !default;
$top-2:                             2rem !default;
$top-3:                             3rem !default;
$top-4:                             4rem !default;
$top-5:                             5rem !default;

// bottom values - CT
$bottom-1:                             1rem !default;
$bottom-2:                             2rem !default;
$bottom-3:                             3rem !default;
$bottom-4:                             4rem !default;
$bottom-5:                             5rem !default;

// left values - CT
$left-1:                             1rem !default;
$left-2:                             2rem !default;
$left-3:                             3rem !default;
$left-4:                             4rem !default;
$left-5:                             5rem !default;

// right values - CT
$right-1:                             1rem !default;
$right-2:                             2rem !default;
$right-3:                             3rem !default;
$right-4:                             4rem !default;
$right-5:                             5rem !default;

// Extra Padding values Map
$paddings: (
  "6": $padding-6,
  "7": $padding-7,
  "8": $padding-8,
  "9": $padding-9,
  "10": $padding-10,
  "11": $padding-11,
  "12": $padding-12,
  "20x": 1.25rem,
  "60x": 3.75rem    
) !default;

// Extra Margins values Map
$margins: (
  "6": $margin-6,
  "7": $margin-7,
  "8": $margin-8,
  "9": $margin-9,
  "10": $margin-10,
  "11": $margin-11,
  "12": $margin-12
) !default;

// Extra Opacity values Map
$opacities: (
  "0": $opacity-0,
  "1": $opacity-1,
  "2": $opacity-2,
  "3": $opacity-3,
  "4": $opacity-4,
  "5": $opacity-5,
  "6": $opacity-6,
  "7": $opacity-7,
  "8": $opacity-8,
  "9": $opacity-9,
  "10": $opacity-10
) !default;

$end: (
  "0": $right-0,
  "1": $right-1,
  "2": $right-2,
  "3": $right-3,
  "4": $right-4,
  "5": $right-5,
  "6": $right-6,
  "7": $right-7,
  "8": $right-8,
  "9": $right-9,
  "10": $right-10
) !default;

$width: (
  "0": $width-0,
  "1": $width-1,
  "2": $width-2,
  "3": $width-3,
  "4": $width-4,
  "5": $width-5,
  "6": $width-6,
  "7": $width-7,
  "8": $width-8,
  "9": $width-9,
  "10": $width-10,
  "15": $width-15,
  "20": $width-20,
  "25": $width-25,
  "30": $width-30,
  "35": $width-35,
  "40": $width-40,
  "45": $width-45,
  "50": $width-50,
  "55": $width-55,
  "60": $width-60,
  "65": $width-65,
  "70": $width-70,
  "75": $width-75,
  "80": $width-80,
  "85": $width-85,
  "90": $width-90,
  "95": $width-95,
  "100": $width-100,
) !default;

$max-width: (
  "100": $max-width-100,
  "200": $max-width-200,
  "300": $max-width-300,
  "400": $max-width-400,
  "500": $max-width-500,
  "100p": $max-width-100-p
) !default;

$height: (
  "100": $height-100,
  "200": $height-200,
  "300": $height-300,
  "400": $height-400,
  "500": $height-500
) !default;

$min-height: (
  "100": $min-height-100,
  "150": $min-height-150,
  "160": $min-height-160,
  "200": $min-height-200,
  "250": $min-height-250,
  "300": $min-height-300,
  "400": $min-height-400,
  "500": $min-height-500,
  "600": $min-height-600
) !default;

$max-height: (
  "100": $max-height-100,
  "150": $max-height-150,
  "160": $max-height-160,
  "200": $max-height-200,
  "250": $max-height-250,
  "300": $max-height-300,
  "400": $max-height-400,
  "500": $max-height-500,
  "600": $max-height-600
) !default;

$letterSpacing: (
  "1": $letter-spacing-1,
  "2": $letter-spacing-2,
  "3": $letter-spacing-3,
  "4": $letter-spacing-4,
  "5": $letter-spacing-5
) !default;


$top: (
  "0": 0,
  "1": $top-1,
  "2": $top-2,
  "3": $top-3,
  "4": $top-4,
  "5": $top-5
) !default;

$right: (
  "0": 0,
  "1": $right-1,
  "2": $right-2,
  "3": $right-3,
  "4": $right-4,
  "5": $right-5
) !default;

$left: (
  "0": 0,
  "1": $left-1,
  "2": $left-2,
  "3": $left-3,
  "4": $left-4,
  "5": $left-5
) !default;

$bottom: (
  "0": 0,
  "1": $bottom-1,
  "2": $bottom-2,
  "3": $bottom-3,
  "4": $bottom-4,
  "5": $bottom-5
) !default;

// blur effect variables
$blur-border-radius-rounded:    40px !default;
$blur-box-shadow:               inset 0px 0px 2px #fefefed1 !default;
$blur-backdrop-filter:          saturate(200%) blur(30px) !default;
$blur-backdrop-filter-less:     saturate(20%) blur(30px) !default;

$shadow-blur-box-shadow:        inset 0 0px 1px 1px rgba(254, 254, 254, .9), 0 20px 27px 0 rgba(0, 0, 0, .05) !default;

// sidebar variables

$sidebar-width: 80px !default;
$sidebar-transition: 1s ease !default;
