// MultiLevel Dropdown Style

.dropdown-menu li {
  position: relative;
}

.dropdown {
  &.dropdown-subitem:after {
    left: 100%;
    bottom: 0;
    width: 50%;
  }

  .dropdown-menu {
    .dropdown-item + .dropdown-menu:before {
      transform: $dropdown-subitem-arrow-rotate;
      left: 0;
      top: 0;
      z-index: -1;
      transition: left .35s ease;
    }
  }

  &.dropdown-subitem:hover .dropdown-item + .dropdown-menu:before {
    left: $dropdown-subitem-left-hover;
  }

  & > .dropdown-menu {
    .dropdown-item + .dropdown-menu {
      transform: $dropdown-multilevel-transform-show;
    }
  }
}

.dropdown .dropdown-menu .dropdown-item+.dropdown-menu {
  right: $dropdown-subitem-position-right;
  left: auto;
  top: 0;
}
// End MultiLevel Dropdown Style

.dropdown-image {
  background-size: cover;
}

@include media-breakpoint-up(lg) {
  .dropdown-xl {
    min-width: $dropdown-xl-min-width;
  }

  .dropdown-lg {
    min-width: $dropdown-lg-min-width;
  }

  .dropdown-md {
    min-width: $dropdown-md-min-width;
  }
}

@include media-breakpoint-down(xl) {
 .dropdown-lg-responsive {
   min-width: $dropdown-lg-width-responsive;
 }
}
