
.bg-accent {
    background: rgba(4, 204, 160, 0.1) !important;
}


.btn-white{
    border: 1px solid $border-color !important;
    color: $font-color;
}

.btn-white:hover, .btn.bg-gradient-white:hover {
    border: 1px solid $border-color !important;
    color: $font-color;
}

.btn-outline-light{
    border: 1px solid $gray-50;
}

.btn-outline-light:hover {
    border: 1px solid $gray-50;
}

/*Aditional text styles*/
.text20 {
    font-size: 1.25rem !important;
}

.text40 {
    font-size: 2.5rem !important;
}

.text-italic{
    font-style: italic !important;
}

.text-underline{
    text-decoration: underline;
}


.border-line{
    border-left: 1px solid lightgrey;
}

.mayus {
    text-transform: uppercase !important;
}

.text-compact{
    letter-spacing: -0.04em
}

.icon-social{
    font-size: 1.5rem !important;
}

.stretch{
    padding: 0.25rem 1rem;
    
}

.hidden {
    display: none;
}

.border-ed{
    border: 1px solid $border-color !important;
}

.noborder, .noborder:hover{
    border: 0px !important;
}


.has-danger label, .text-errors, .text-danger-error {
    color: $danger !important;
}
